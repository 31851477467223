<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          <v-btn
            height="29"
            class="label text-capitalize font-weight-regular"
            depressed
            color="secondary"
            :ripple="false"
          >
            {{ $t('page_users_list_active_user_quick_stats_text') }}
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row class="font-weight-medium subtitle-1" no-gutters>
            <v-col cols="4">
              {{ $t('page_users_list_daily_login_text') }}:
              {{ getUserStats.day.login }} / {{ getUserStats.total }} =
              {{ dailyLoginPercentage }}%
            </v-col>
            <v-col cols="6">
              {{ $t('page_users_list_dau_text') }}:
              {{ getUserStats.day.active }} / {{ getUserStats.total }} =
              {{ dauPercentage }}%
            </v-col>
            <v-col cols="4">
              {{ $t('page_users_list_monthly_login_text') }}:
              {{ getUserStats.month.login }} / {{ getUserStats.total }} =
              {{ monthlyLoginPercentage }}%
            </v-col>
            <v-col cols="6">
              {{ $t('page_users_list_mau_text') }}:
              {{ getUserStats.month.active }} / {{ getUserStats.total }} =
              {{ mauPercentage }}%
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-card>
        <v-card-title class="px-0 pb-2">
          <v-container class="px-0 py-0 mb-2">
            <v-row align="end" class="px-8">
              <v-col cols="auto">
                <v-btn
                  height="29"
                  class="label text-capitalize font-weight-regular"
                  depressed
                  color="secondary"
                  :ripple="false"
                >
                  <v-icon left>$users</v-icon>
                  {{ $t('page_users_list_title') }}
                </v-btn>
              </v-col>

              <v-spacer></v-spacer>
              <v-col md="3" cols="12">
                <v-text-field
                  v-model="search"
                  dense
                  outlined
                  :placeholder="`${$t('search')}...`"
                  hide-details
                  v-on:keyup.13="getDataFromApi()"
                ></v-text-field>
              </v-col>

              <v-col md="3" cols="12">
                <v-select
                  dense
                  outlined
                  :placeholder="$t('select_business')"
                  hide-details
                  :items="companiesList"
                  item-text="display_name"
                  item-value="id"
                  append-icon="$customChevronDown"
                  v-model="selectedCompany"
                  @change="getDataFromApi()"
                ></v-select>
              </v-col>

              <v-col cols="auto">
                <v-btn
                  text
                  color="primary"
                  class="text-capitalize"
                  :to="{ name: 'UserCreate' }"
                >
                  <v-icon>mdi-plus</v-icon>
                  {{ $t('page_users_list_add_user_text') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text class="px-0 pb-0">
          <Table
            :headers="headers"
            :items="allUsers"
            :search.sync="search"
            :loading="loading"
            :total-records="userPagination.records_total"
            :number-of-pages="userPagination.total_pages"
            @update:options="getDataFromApi"
            @click:row="handleRowClick"
          >
            <template v-slot:item.id="{ item }">
              <div class="primary--text">{{ item.id }}</div>
            </template>

            <template v-slot:item.avatar="{ item }">
              <v-avatar color="avatar-bg-grey" size="36" class="white--text">
                <v-img :src="item.photo"></v-img>
              </v-avatar>
            </template>

            <template v-slot:item.company="{ item }">
              <div v-if="item.company">{{
                item.company.display_name
              }}</div>
              <div
                v-if="item.company.prefecture !== null"
                class="text-furigana-fade"
              >
                {{ item.company.prefecture.name }}, {{ item.company.city }}
              </div>
            </template>

            <template v-slot:item.last_name="{ item }">
              <div>{{ item.last_name }} {{ item.first_name }}</div>
              <div class="text-furigana-fade">
                {{ item.last_name_kana }} {{ item.first_name_kana }}
              </div>
            </template>

            <template v-slot:item.last_login_at="{ item }">
              {{ item.last_login_at ? item.last_login_at : '--' }}
            </template>

            <template v-slot:item.groups="{ item }">
              {{
                Array.prototype.map.call(item.groups, s => s.name).join(', ')
              }}
            </template>

            <template v-slot:item.action="{ item }">
              <v-btn color="secondary" icon :to="`/users/${item.id}/edit`">
                <v-icon size="16">$edit</v-icon>
              </v-btn>
            </template>
          </Table>
          <v-divider></v-divider>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
import { mapGetters } from 'vuex'

export default {
  name: 'index',
  components: { Table },
  created() {
    this.getDataFromApi()
    this.getUserStateAndCompanies()
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: this.$t('id'), align: 'center', value: 'id' },
        { text: '', align: 'center', value: 'avatar', sortable: false },
        {
          text: this.$t('page_users_list_table_header_business'),
          align: 'center',
          value: 'company'
        },
        {
          text: this.$t('page_users_list_table_header_user_name'),
          align: 'center',
          value: 'last_name'
        },
        {
          text: this.$t('page_users_list_table_header_position'),
          align: 'center',
          value: 'position'
        },
        {
          text: this.$t('page_users_list_table_header_email'),
          align: 'center',
          value: 'email'
        },
        {
          text: this.$t('page_users_list_table_header_group'),
          align: 'center',
          value: 'groups'
        },
        {
          text: this.$t('page_users_list_table_header_last_login_date'),
          align: 'center',
          value: 'last_login_at',
          sortable: false
        },
        {
          text: this.$t('page_users_list_table_header_registered'),
          align: 'center',
          value: 'created_at'
        },
        {
          text: this.$t('actions'),
          align: 'center',
          value: 'action',
          sortable: false
        }
      ],
      search: '',
      deleteDialog: false,
      selectedCompany: null
    }
  },
  computed: {
    ...mapGetters(['allUsers', 'userPagination', 'getUserStats']),
    companiesList() {
      let companies = this.$store.getters.userGetCompanyList
      companies.unshift({ id: null, display_name: 'All' })
      return companies
    },
    dailyLoginPercentage() {
      return Math.round(
        (this.getUserStats.day.login / this.getUserStats.total) * 100
      )
    },
    monthlyLoginPercentage() {
      return Math.round(
        (this.getUserStats.month.login / this.getUserStats.total) * 100
      )
    },
    dauPercentage() {
      return Math.round(
        (this.getUserStats.day.active / this.getUserStats.total) * 100
      )
    },
    mauPercentage() {
      return Math.round(
        (this.getUserStats.month.active / this.getUserStats.total) * 100
      )
    }
  },
  methods: {
    handleRowClick(item) {
      this.$router.push({ name: 'UserEdit', params: { id: item.id } })
    },
    getUserStateAndCompanies() {
      this.loading = true
      this.$store.dispatch('USER_STATS_GET')
      this.$store.dispatch('USER_GET_COMPANY_LIST')
      this.loading = false
    },
    getDataFromApi(e) {
      let params = {}

      if (this.selectedCompany) {
        params.company_id = this.selectedCompany
      }

      if (this.search != '') params.search = this.search

      if (e !== undefined) {
        if (e.sortBy[0] !== undefined) {
          let order = e.sortDesc[0] ? 'desc' : 'asc'
          params.sort = `${e.sortBy[0]}:${order}`
        }

        if (e.page !== undefined && e?.itemsPerPage !== undefined) {
          params.page = e.page
          params.paginate = e.itemsPerPage
        }
      }
      this.loading = true
      this.$store.dispatch('GET_ALL_USERS', params)
      this.loading = false
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
